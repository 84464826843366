<template>
	<!--Checkout-->
	<div class="card-layout confirm">
	    <div class="card shadow-sm">
	        <div class="card-body p-0">
	            <div class="form left align-self-center">
	                <div class="success-animation">
	                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
	                    <h2 class="success-title" v-html="message"></h2>
	                </div>
	            </div>
	            <div class="info right">
	            <!-- 	<pre>{{$store.state.orderDetails}}</pre> -->
	                <h3>Customer Information</h3>
	                <table class="table table-bordered mb-5">
	                    <tr>
	                        <th>Name</th>
	                        <td>
	                        	<slot v-if="$store.state.orderDetails!=null">
                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
                          		  		<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos.customer_address!=null">
	                                    		{{$store.state.orderDetails.dataInfos.customer_address.name}}
	                                    	</slot>
	                                    </slot>
                                    </slot>
                                </slot>
	                        </td>
	                    </tr>
	                    <tr>
	                        <th>Phone</th>
	                        <td>
	                        	<slot v-if="$store.state.orderDetails!=null">
                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
                          		  		<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos.customer_address!=null">
	                                    		{{$store.state.orderDetails.dataInfos.customer_address.phone}}
	                                    	</slot>
	                                    </slot>
                                    </slot>
                                </slot>
	                        </td>
	                    </tr>
	                    <tr>
	                        <th>Email</th>
	                        <td>
	                        	<slot v-if="$store.state.orderDetails!=null">
                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
                          		  		<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos.customer_info!=null">
	                                    		{{$store.state.orderDetails.dataInfos.customer_info.email}}
	                                    	</slot>
	                                    </slot>
                                    </slot>
                                </slot>
                            </td>
	                    </tr>
	                    <tr>
	                        <th>Address</th>
	                        <td>
	                        	<slot v-if="$store.state.orderDetails!=null">
                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
                          		  		<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos.customer_address!=null">
	                                    		{{$store.state.orderDetails.dataInfos.customer_address.address}}
	                                    	</slot>
	                                    	<slot v-if="$store.state.orderDetails.dataInfos.area_info!=null">
	                                    		,{{$store.state.orderDetails.dataInfos.area_info.name}}
	                                    		<slot v-if="$store.state.orderDetails.dataInfos.area_info.district_info=null">
	                                    			,{{$store.state.orderDetails.dataInfos.area_info.district_info.name}}
	                                    		</slot>
	                                    	</slot>
	                                    </slot>
                                    </slot>
                                </slot>
	                        </td>
	                    </tr>
	                    <tr>
	                        <th>Notes</th>
	                        <td>
	                        	<slot v-if="$store.state.orderDetails!=null">
                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
                          		  		<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos.order_notes.length>0">
	                                    		<slot v-for="noteInfo in $store.state.orderDetails.dataInfos.order_notes">
		                                    		{{noteInfo.note}}<br>
		                                    	</slot>
	                                    	</slot>
	                                    </slot>
                                    </slot>
                                </slot>
                            </td>
	                    </tr>
	                </table>
	                <h3>Order details</h3>
	                <table class="table table-bordered mb-3">
	                    <tr>
	                        <td>
	                            <small>ORDER NUMBER:</small>
	                            <h4 class="mb-0">
		                            <slot v-if="$store.state.orderDetails!=null">
	                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    		#MWBD-{{$store.state.orderDetails.dataInfos.id}}
	                                    	</slot>
	                                    </slot>
	                                </slot>
	                            </h4>
	                        </td>
	                        <td>
	                            <small>DATE:</small>
	                            <h4 class="mb-0">
	                            	 <slot v-if="$store.state.orderDetails!=null">
	                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    		{{$store.state.orderDetails.dataInfos.created_at | formatDateTime}}
	                                    	</slot>
	                                    </slot>
	                                </slot>
	                            </h4>
	                        </td>
	                        <td>
	                            <small>TOTAL:</small>
	                            <h4 class="mb-0">৳ 
	                            	<slot v-if="$store.state.orderDetails!=null">
	                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                                    		{{((parseFloat($store.state.orderDetails.dataInfos.price)+parseFloat($store.state.orderDetails.dataInfos.deliveryCharge))-(parseFloat($store.state.orderDetails.dataInfos.discount)+parseFloat($store.state.orderDetails.dataInfos.invoiceDiscount)+parseFloat($store.state.orderDetails.dataInfos.promoDiscount)))}}
	                                    	</slot>
	                                    </slot>
	                                </slot>
	                            </h4>
	                        </td>
	                        <td>
	                            <small>PAYMENT METHOD:</small>
	                            <h4 class="mb-0">
	                            	<slot v-if="$store.state.orderDetails!=null">
	                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                            				{{getPaymentMessage($store.state.orderDetails.dataInfos)}}
	                            			</slot>
	                            		</slot>
	                            	</slot>
	                            </h4>
	                        </td>
	                    </tr>
	                </table>
	                <table class="table table-bordered mb-5">
	                    <thead class="table-light">
	                        <tr>
	                            <th>PRODUCT</th>
	                            <th>Rate</th>
	                            <th>Discount</th>
	                            <th>Price</th>
	                        </tr>
	                    </thead>
	                    <tbody>
	                      <slot v-if="$store.state.orderDetails!=null">
                  		  		<slot v-if="$store.state.orderDetails.msgFlag==true">
                            		<slot v-if="$store.state.orderDetails.dataInfos!=null">
                            			<slot v-for="orderDetails in $store.state.orderDetails.dataInfos.order_details">
					                        <tr>
					                            <td>{{orderDetails.product_info.name}}× {{orderDetails.quantity}}</td>
					                            <td>৳ {{orderDetails.sellRate}}</td>
					                            <td>৳ {{orderDetails.discount}}</td>
					                            <td>৳ {{(orderDetails.sellPrice-orderDetails.discount)|formatNumberWithOutDecimal}}</td>
					                        </tr>
					                    </slot>
					                </slot>
					            </slot>
					        </slot>

	                       
	                    </tbody>
	                    <tfoot class="table-light">
	                        <tr>
	                            <th>SUBTOTAL:</th>
	                            <th colspan="3" class="text-right">
	                            	<slot v-if="$store.state.orderDetails!=null">
		                  		  		<slot v-if="$store.state.orderDetails.msgFlag==true">
		                            		<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                            				৳ {{$store.state.orderDetails.dataInfos.price}}
	                            			</slot>
	                            		</slot>
	                            	</slot>
	                        </th>
	                        </tr>
	                        <tr>
	                            <th>Discount:</th>
	                            <th colspan="3" class="text-right">
	                            	<slot v-if="$store.state.orderDetails!=null">
		                  		  		<slot v-if="$store.state.orderDetails.msgFlag==true">
		                            		<slot v-if="$store.state.orderDetails.dataInfos!=null">
                            					৳ {{(parseFloat($store.state.orderDetails.dataInfos.discount)+parseFloat($store.state.orderDetails.dataInfos.invoiceDiscount)+parseFloat($store.state.orderDetails.dataInfos.promoDiscount))}}
                            				</slot>
                            			</slot>
                            		</slot>
                            	</th>
	                        </tr>
	                        <tr>
	                            <th>SHIPPING:</th>
	                            <th colspan="3" class="text-right">
	                            	<slot v-if="$store.state.orderDetails!=null">
		                  		  		<slot v-if="$store.state.orderDetails.msgFlag==true">
		                            		<slot v-if="$store.state.orderDetails.dataInfos!=null">
		                            			৳ {{parseFloat($store.state.orderDetails.dataInfos.deliveryCharge)}}
		                            		</slot>
		                            	</slot>
		                            </slot>
		                        </th>
	                        </tr>
	                        <tr>
	                            <th>TOTAL:</th>
	                            <th colspan="3" class="text-right">
	                            	<slot v-if="$store.state.orderDetails!=null">
		                  		  		<slot v-if="$store.state.orderDetails.msgFlag==true">
		                            		<slot v-if="$store.state.orderDetails.dataInfos!=null">
		                            			৳ {{((parseFloat($store.state.orderDetails.dataInfos.price)+parseFloat($store.state.orderDetails.dataInfos.deliveryCharge))-(parseFloat($store.state.orderDetails.dataInfos.discount)+parseFloat($store.state.orderDetails.dataInfos.invoiceDiscount)+parseFloat($store.state.orderDetails.dataInfos.promoDiscount)))}}
		                            		</slot>
		                            	</slot>
		                            </slot>
		                        </th>
	                        </tr>
	                        <tr>
	                            <th>PAYMENT METHOD:</th>
	                            <th colspan="3" class="text-right"><strong>
	                            	<slot v-if="$store.state.orderDetails!=null">
	                          		  	<slot v-if="$store.state.orderDetails.msgFlag==true">
	                                    	<slot v-if="$store.state.orderDetails.dataInfos!=null">
	                            				{{getPaymentMessage($store.state.orderDetails.dataInfos)}}
	                            			</slot>
	                            		</slot>
	                            	</slot>
	                            </strong></th>
	                        </tr>
	                    </tfoot>
	                </table>
	                <div class="row">
	                    <div class="col-lg-12 text-center">
	                        <router-link to="/dashboard" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Back to Order History</router-link>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
	<!--/Checkout-->
</template>

<script>
export default{
	data(){
		return{
			orderId:null,
			message:null,
			orderInfos:[],
		}
	},
	beforeMount(){
	    let orderId=this.$route.params.orderId;
	    this.message="Your Order Has Been Successfully.<br>Your Order ID Is: MWBD-"+orderId;
	    // this.getCartInfos();
	    this.resetCartInfo();
	    this.getOrderDetails(orderId);
  },
  methods:{
  	getPaymentMessage(dataInfo){
  		if(dataInfo.isCashOn==1){
				return "Cash On Delivery";
			}
			else{
				if(dataInfo.isBkashPaid==1 || dataInfo.isBkashPaid==2){
					if(dataInfo.isBkashPaid==1){
						return "Bkash Paid.";
					}
					else{
						return "Waiting For Bkash Payment.";
					}
				}
				else
				{
					if(dataInfo.isOnlinePaid==1){
						return "Online Paid.";
					}
					else{
						
						if(dataInfo.isOnlinePaid==3){
							return "Online Payment Cancelled.";
						}
						else
							if(dataInfo.isOnlinePaid==4){
								return "Online Payment Failed."
							}
						else if(dataInfo.isOnlinePaid==2){
							return "Waiting For Online Payment";
						}
					}
				}
			}
		},
  	getOrderDetails(orderId){
  		let customerInfo=this.$store.state.customerInfo;

  		let formData=new FormData();
      	formData.append("dataId",orderId);
      	formData.append("phone",customerInfo.phone);
      	formData.append("remember",customerInfo.remember);
      	formData.append("token",customerInfo.token);
        
        this.$http.post("order/details",formData)
         .then(response=>{
         	if(response.data.msgFlag==true)
            	this.$store.commit("storeOrderDetails",response.data);
            	// this.orderInfos=response.data.dataInfos;
         })
         .catch(error=>{console.dir(error)})
         .finally();
  	},
  	resetCartInfo(){
  		let formData=new FormData();
  		formData.append("nothing",null);
  		this.$http.post("product/add/to/cart",formData)
  		 .then(response=>{
  		 	this.$store.commit("storeCartInfos",response.data.cartInfos);
  		 	this.$store.commit("storePriceInfos",response.data);
  		 	this.$store.commit("storePreOrderCartInfos",response.data.cartInfos);
  		 	this.$store.commit("storePreOrderPriceInfos",response.data);
  		 })
  		 .catch(error=>{console.dir(error)})
  		 .finally();
  	}
  }
}
</script>